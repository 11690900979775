import(/* webpackMode: "eager", webpackExports: ["User"] */ "/vercel/path0/apps/netthandel/src/assets/icons.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/netthandel/src/cms/live-preview/LivePreview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/components/Header/HeaderLinkBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/components/Header/LinkGroupSideMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MenuNavigation","MenuTop"] */ "/vercel/path0/apps/netthandel/src/components/Header/MenuState.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchBar"] */ "/vercel/path0/apps/netthandel/src/components/Header/Search/SearchBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SkipToContentLink"] */ "/vercel/path0/apps/netthandel/src/components/Header/skipToContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UniverseThemeProvider"] */ "/vercel/path0/apps/netthandel/src/theming/UniverseTheme.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/components/Carousel/useCarousel.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/components/Modal/Modal.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/components/Table/TableRows.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/components/Tabs/Tabs.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/components/Tooltip/Tooltip.js");
